@import "./bootstrap.css";
@import "./base.css";
@import "./standard.css";

/* Login page */
.signin-page {
  height: max(calc(100vh - 70px), 700px);
  background-color: #fff;
  width: 100%;
}

.first-div {
  height: 700px;
  width: 600px;
  border-radius: 20px;
  background-color: #aaa;

  animation-duration: 1.1s;
  animation-name: changecolor1;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changecolor1 {
  from {
    background-color: #aaa;
  }

  to {
    background-color: #ccc;
  }
}

.second-div {
  height: 600px;
  width: 500px;
  border-radius: 17px;
  background-color: #555;

  animation-duration: 1.6s;
  animation-name: changecolor2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changecolor2 {
  from {
    background-color: #555;
  }

  to {
    background-color: #888;
  }
}

.third-div {
  height: 500px;
  width: 400px;
  border-radius: 17px;
  background-color: #202020;
  padding: 0px 0px 40px 0px;
}

.third-div h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 35px;
  color: #f3f3f3;
  margin-bottom: 50px;
}

.third-div form {
  padding: 70px 40px 80px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
}

.third-div form input {
  margin: 20px 0px 0px 0px;
  width: 250px;
  font-size: 16px;
  line-height: 25px;
  border: 3px solid #ccc;
  border-radius: 5px;
  padding: 3px;
}

.third-div form input::placeholder {
  color: #777;
}

.third-div form input:focus::placeholder {
  color: #888;
}

.third-div form input:focus {
  border: 3px solid #999;
  outline: none;
}

.third-div form button {
  margin: 40px 0px 0px 0px;
  line-height: 30px;
  border-radius: 5px;
  border: 2px solid #777;
  color: #202020;
  font-size: 16px;
  font-weight: 400;
  background-color: #f3f3f3;
}

.third-div form button:hover {
  cursor: pointer;
  font-weight: 500;
  background-color: #e3e3e3;
}

.need-acount {
  color: #f3f3f3;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
}

.need-acount a {
  color: #f3f3f3;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
}

.errmsg {
  font-family: "Noto Sans", sans-serif;
  color: #202020;
  display: block;
  background-color: #dd6b6b;
  border: 1px solid #ff0000;
  padding: 0px 10px;
  margin: 0px 0px 10px 0px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
}
.no-errmsg {
  visibility: hidden;
  font-family: "Noto Sans", sans-serif;
  color: #202020;
  display: block;
  background-color: #dd6b6b;
  border: 1px solid #ff0000;
  margin: 0px 0px 10px 0px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
}

/* Logout page */
.logout-div {
  background-color: #e1e1e1;
  margin-bottom: 30px;
  border-radius: 20px;
  width: 400px;
  height: 200px;
}

.logout-div p,
.logout-div a,
.logout-div p a {
  color: #202020;
  font-size: 17px;
  line-height: 50px;
}

/* Page 404 */
.page-404 {
  height: calc(80vh);
}

.page-404 h2 {
  font-size: 25px;
  line-height: 40px;
  font-weight: 600;
  color: #202020;
  text-align: center;
}

.page-404 h3 {
  padding-top: 30px;
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  color: #202020;
  text-align: center;
}

.page-404 h3 a {
  color: #202020;
}

/* Other */

.loading-big {
  width: 100%;
  height: 100vh;
}

.app-page-title {
  line-height: 85px;
  font-size: 30px;
  font-weight: 600;
  padding: 10px;
  font-family: "Raleway", sans-serif;
  color: #202020;
}

.dashboard-page h2 {
  margin: 50px 0px;
}

.blue-color {
  color: #7272cc;
}

.red-color {
  color: #da6363;
}

.green-color {
  color: #60c65c;
}

.transparent-button {
  border: none;
  background-color: transparent;
  margin-bottom: 2px;
}

.transparent-button:hover {
  cursor: pointer;
  text-shadow: 0px 1px 1px #ddd;
}
