/* contains some common base classes required by several components/pages */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,700&display=swap");
@import "./standard.css";

:root {
  --blue-color: #377dd3;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #202020;
}

.frcc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fcac {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.frac {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.fccc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fcsc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.fccs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.frsc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.frcs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.fcss {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.frbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.frbs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.f-wrap {
  flex-wrap: wrap;
}

.w-29 {
  width: 29%;
}

.w-49 {
  width: 49%;
}

.w-69 {
  width: 69%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-70px {
  width: 70px;
}

.w-90px {
  width: 90px;
}

.w-22px {
  width: 22px;
}

.w-40px {
  width: 40px;
}

.w-140px {
  width: 140px !important;
}

.w-200px {
  width: 200px !important;
}

.w-400px {
  width: 400px !important;
}

.mw-700px {
  max-width: 700px !important;
}

.mw-600px {
  max-width: 600px !important;
}

.mw-400px {
  max-width: 400px !important;
}

.h-80px {
  height: 80px;
}

.lh-80px {
  line-height: 80px;
}

.lh-23px {
  line-height: 23px;
}

.font-light {
  font-weight: 300 !important;
}

.font-regular {
  font-weight: 400 !important;
}

.font-semibold {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 600 !important;
}

.font-extrabold {
  font-weight: 700 !important;
}

.font-larger {
  font-size: larger;
}

.font-large {
  font-size: large;
}

.font-medium {
  font-size: medium;
}

.font-small {
  font-size: small;
}

.m-20px {
  margin: 20px 0px;
}

.p-20px {
  padding: 20px;
}

.pb-120px {
  padding-bottom: 120px;
}

.pb-50px {
  padding-bottom: 50px;
}

.pb-20px {
  padding-bottom: 20px;
}

.blue-text-color {
  color: var(--blue-color) !important;
}

.italic-p {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1050px) {
  .phone-no-display {
    display: none !important;
  }
}

.italic {
  font-style: italic;
}

.no-decoration {
  text-decoration: none;
}

.page-title {
  font-size: 23px;
  text-align: center;
  line-height: 40px;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hide-smoothly {
  height: 0px;
  display: none;
  transition: 100ms;
}
